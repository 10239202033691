<template>
  <div class="relative">
    <h4 class="text-center mt-3">Seller's Profile</h4>
    <div class=" p-3 md:p-6 rounded-lg">
      <div class="flex flex-col md:flex-row md:justify-center ">
        <div class="bg-darkblue rounded-lg">
          <div class=" p-3 ">
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Name:</span> {{ seller.name }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Email: </span>{{ seller.email }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Phone:</span> {{ seller.phoneNumber }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> Address:</span> {{ seller.address }}
            </p>
            <p class="md:text-xs text-white mb-2">
              <span class="font-bold"> No of purchases: </span
              >{{ getSellersPurchases.totalCount }}
            </p>
            <div class="mt-2 ">
              <button
                @click="EditSeller"
                type="button"
                class="bg-green-600 hover:bg-lightblue hover:text-black mb-2 mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Edit
              </button>
              <button
                @click="showStatementModal = true"
                type="button"
                class="bg-green-600 hover:bg-lightblue hover:text-black mb-2 mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Generate Account Statement
              </button>
              <button
                @click="showDeleteModal = true"
                type="button"
                class="bg-red-600 md:text-xs hover:bg-lightblue mb-2 hover:text-black text-white rounded-lg pt-1 pb-1 pl-2 pr-2"
              >
                Delete
              </button>
            </div>
          </div>
          <div v-if="loading.seller">
            <svg
              class="ml-auto mr-auto animate-spin"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>
        <div>
          <div class="overflow-auto md:overflow-visible mt-4 md:mt-0">
            <h4 class="text-center mt-4 block md:hidden">Purchases</h4>

            <t-table
              id="my-table"
              :classes="table_classes"
              :headers="table_header"
              :data="table_data"
            >
              <template slot="column" slot-scope="props">
                <td
                  @click="NavigateToSinglePurchase(props)"
                  :class="props.tdClass"
                >
                  {{ props.text }}
                </td>
              </template>
            </t-table>
          </div>
          <div>
            <transition name="slide-fade">
              <NotificationModal
                v-if="showDeleteModal"
                :modalData="modalData"
                @modal-success="DeleteSeller($event)"
                @modal-close="CloseModal($event)"
              />
            </transition>
          </div>
          <div v-if="loading.search" class="flex justify-center flex-col">
            <div>
              <p class="text-red-700 text-center md:text-xs font-bold  ">
                Loading,please wait...
              </p>
            </div>
            <div class="mt-2">
              <svg
                class="ml-auto mr-auto animate-spin"
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48 25C48 12.2975 37.7025 2 25 2"
                  stroke="black"
                  stroke-width="4"
                />
                <path
                  d="M48 25C48 37.7025 37.7025 48 25 48"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M2 25C2 12.2975 12.2975 2 25 2"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M25 48C12.2975 48 2 37.7025 2 25"
                  stroke="#C9000C"
                  stroke-width="4"
                />
              </svg>
            </div>
          </div>

          <div
            v-if="
              getSellersPurchases.result.length < 1 && loading.search != true
            "
            class="mb-6 mt-2"
          >
            <p class="text-center md:text-xs">
              No purchases found for this seller
            </p>
          </div>
          <div class="mb-6 mt-2 text-center">
            <button
              :class="
                getSellersPurchases.hasPrevious != true
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              "
              :disabled="
                getSellersPurchases.hasPrevious != true ||
                  loading.search != false
              "
              @click="Navigate('prev')"
              class=" text-darkblue md:text-xs  hover:text-blue-600 font-bold"
            >
              Prev
            </button>

            <span class="ml-2 mr-2 md:text-xs"
              >{{ currentPage }}/{{ number_of_pages }}</span
            >
            <button
              :class="
                getSellersPurchases.hasMore != true
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              "
              :disabled="
                getSellersPurchases.hasMore != true || loading.search != false
              "
              @click="Navigate('next')"
              class=" text-darkblue md:text-xs hover:text-blue-600 font-bold"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <t-modal
        :fixedClasses="fixedClasses"
        :classes="defaultClasses"
        v-model="showStatementModal"
        header="Generate Seller's statement"
      >
        <div class="text-sm p-l-1 pr-2">
          <h6 class="text-sm mb-3">
            Select the date range below and the statement will be sent to your
            mail
          </h6>
          <div class=" ">
            <t-datepicker
              :classes="datePickerDefaultClass"
              :dateFormat="'Y-m-d H:i:S'"
              :userFormat="'Y-m-d'"
              :placeholder="'Select date range'"
              :range="true"
              v-model="selectedDate"
            />
          </div>
        </div>
        <template v-slot:footer>
          <div class="flex justify-between">
            <button
              @click="showStatementModal = false"
              class="text-red-700 mr-2 text-xs pl-3 pr-3 pt-1 pb-1"
              type="button"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="PrintStatementPdf"
              class=" bg-darkblue flex justify-between ml-2 rounded-lg text-xs pl-3 pr-3 pt-2 pb-2 text-white"
            >
              <div v-if="loading.statementPdf" class="mr-2">
                <svg
                  class="ml-auto mr-auto animate-spin"
                  width="18"
                  height="18"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M48 25C48 12.2975 37.7025 2 25 2"
                    stroke="black"
                    stroke-width="4"
                  />
                  <path
                    d="M48 25C48 37.7025 37.7025 48 25 48"
                    stroke="white"
                    stroke-width="4"
                  />
                  <path
                    d="M2 25C2 12.2975 12.2975 2 25 2"
                    stroke="white"
                    stroke-width="4"
                  />
                  <path
                    d="M25 48C12.2975 48 2 37.7025 2 25"
                    stroke="#C9000C"
                    stroke-width="4"
                  />
                </svg>
              </div>
              <span>
                Generate Statement
              </span>
            </button>
          </div>
        </template>
      </t-modal>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import NotificationModal from "../../components/NotificationModal.vue";
  import { modalMixin } from "@/assets/helpers/modalMixin";
  import { datePickerMixin } from "@/assets/helpers/datePickerMixin";
  import { mapMutations } from "vuex";

  import { permissionMixin } from "@/assets/helpers/permissionMixin";

  export default {
    name: "SingleSeller",
    props: {},
    NotificationModal,
    components: { NotificationModal },
    mixins: [permissionMixin, datePickerMixin, modalMixin],

    apollo: {
      getSellersPrintableStatement: {
        query: gql`
          query getSellersPrintableStatement(
            $query: SellersPrintableStatementInput!
          ) {
            getSellersPrintableStatement(query: $query) {
              status
            }
          }
        `,
        variables() {
          return {
            query: {
              sellerId: this.$route.params.id,
              dateFilter: this.formattedDateFilter,
            },
          };
        },
        skip() {
          return this.skipStatementQuery;
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        result({ loading, errors }) {
          if (!loading && !errors) {
            this.$emit("show-alert", {
              type: "success",
              message:
                "Your statement will be generated and sent to your mail when it's ready",
            });
          }
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.statementPdf = true;
            return;
          }

          this.showStatementModal = false;
          this.loading.statementPdf = false;
        },
      },
      getSellersPurchases: {
        query: gql`
          query getSellersPurchases($query: SellersPurchaseInput!) {
            getSellersPurchases(query: $query) {
              result {
                _id
                serialId
                authorId {
                  _id
                  name
                }

                createdAt
                serialId
                purchaseTotal
                purchaseBalance
                createdAt
              }
              totalCount
              hasMore
              hasPrevious
            }
          }
        `,
        variables() {
          return {
            query: {
              cursor: Number(this.searchCursor),
              limit: this.searchData.limit,
              type: this.searchType,
              sellerId: this.$route.params.id,
            },
          };
        },
        skip() {
          if (this.appPermissions["seller"]) {
            return this.appPermissions["seller"]["read"] == false;
          }
          return true;
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.search = true;
            return;
          }

          this.loading.search = false;

          // this.SetBarChartLoadingStatus(false);
        },
      },
      seller: {
        query: gql`
          query seller($id: ID!) {
            seller(id: $id) {
              name
              phoneNumber
              address
              email
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.seller = true;
            return;
          }

          this.loading.seller = false;
        },
      },
    },
    data() {
      return {
        showDeleteModal: false,
        showStatementModal: false,
        selectedDate: [],
        getSellersPrintableStatement: null,
        skipStatementQuery: true,
        formattedDateFilter: {
          from: "",
          to: "",
        },
        modalData: {
          title: "Delete Seller",
          message:
            "Are you sure you want to delete this seller? You can't get it back if you continue.",
          cancelText: "Cancel",
          successText: "Delete",
          loading: false,
        },
        searchData: {
          limit: 5,
          sellerId: "",
          cursor: 0,
        },
        searchType: "GT",
        loading: {
          search: false,
          seller: false,
          statementPdf: false,
        },
        searchCursor: 0,
        search: {
          result: [],
          hasMore: false,
          hasPrevious: false,
        },
        seller: {},
        getSellersPurchases: {
          result: [],
          hasMore: false,
          hasPrevious: false,
          totalCount: 0,
        },
        currentPage: 1,
        table_header: [
          {
            value: "batchNo",
            text: "Batch no",
            className: "",
          },
          {
            value: "purchaseTotal",
            text: "Purchase Total(₦)",
            className: "fortunate-table",
          },
          {
            value: "purchaseBalance",
            text: "Purchase Balance(₦)",
            className: "fortunate-table",
          },
          {
            value: "createdBy",
            text: "CreatedBy",
            className: "fortunate-table",
          },
          {
            value: "regDate",
            text: "Reg Date",
          },
        ],
        table_data: [],

        mode: "new",
        table_dropdown_class: {
          button:
            "block font-black break-normal px-4 py-2 text-black transition duration-100 ease-in-out bg-transparent border border-transparent rounded  hover:text-green-400 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
          wrapper: "inline-flex flex-col",
          dropdownWrapper: "relative z-10",
          dropdown:
            "origin-top-left absolute left-0 w-32 rounded shadow bg-white mt-1",
          enterClass: "",
          enterActiveClass:
            "transition ease-out duration-100 transform opacity-0 scale-95",
          enterToClass: "transform opacity-100 scale-100",
          leaveClass: "transition ease-in transform opacity-100 scale-100",
          leaveActiveClass: "",
          leaveToClass: "transform opacity-0 scale-95 duration-75",
        },

        table_classes: {
          table:
            "min-w-full  text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border",
          thead: "border-b-2 md:text-xs border-darkblue ",
          theadTr: "",
          theadTh:
            "px-3 py-2 font-bold md:text-center text-left bg-transparent ",
          tbody: "bg-white divide-y divide-gray-100",
          tr: "cursor-pointer hover:bg-dashboardbody  rounded-lg text-xs",
          td: "px-3 py-2  whitespace-no-wrap",
          tfoot: "",
          tfootTr: "",
          tfootTd: "",
        },

        // paginated_table_data: [],
        icons: {
          pen: require("@/assets/imgs/icons/white-pen.svg"),
        },
      };
    },
    computed: {
      new_mode: function() {
        return this.mode === "new";
      },
      old_mode: function() {
        return this.mode === "old";
      },
      number_of_pages: function() {
        return Math.ceil(
          Number(this.getSellersPurchases.totalCount) /
            Number(this.searchData.limit)
        );
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      async PrintStatementPdf() {
        if (this.selectedDate.length > 0) {
          if (this.selectedDate.length > 1) {
            this.formattedDateFilter = {
              from: new Date(this.selectedDate[0]).getTime(),
              to: new Date(this.selectedDate[1]).getTime(),
            };
            this.skipStatementQuery = false;
            return;
          }
          this.formattedDateFilter = {
            from: new Date(this.selectedDate[0]).getTime(),
            to: new Date(this.selectedDate[0]).getTime(),
          };
          this.skipStatementQuery = false;
        } else {
          return this.$emit("show-alert", {
            type: "error",
            message: "Select a date range before you can perform this action",
          });
        }
      },
      NavigateToSinglePurchase(row) {
        this.$router.push({
          name: "single_purchase",
          params: { id: this.getSellersPurchases.result[row.rowIndex]._id },
        });
      },
      async DeleteSeller() {
        this.modalData.loading = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!) {
                deleteSeller(id: $id) {
                  _id
                  serialId
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
            },
          });

          if (!errors && !!data.deleteSeller._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Seller deleted successfully`,
            });

            return this.$router.push("/app/sellers");
          }
          this.modalData.loading = false;

          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting seller,check your network or contact your developer`,
          });
        } catch (error) {
          this.modalData.loading = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting seller,check your network or contact your developer`,
          });
        }
      },
      CloseModal() {
        this.showDeleteModal = false;
        this.modalData.loading = false;
      },
      Navigate(type) {
        switch (type) {
          case "prev":
            this.searchType = "LT";
            this.currentPage--;

            this.searchCursor = this.getSellersPurchases.result[0].serialId;
            break;

          case "next":
            this.searchType = "GT";
            this.currentPage++;
            this.searchCursor = this.getSellersPurchases.result[
              this.getSellersPurchases.result.length - 1
            ].serialId;
            break;

          default:
            break;
        }
      },
      TableButton() {},
      EditSeller() {
        this.$router.push({
          name: "manageSeller",
          params: { mode: "edit", id: this.$route.params.id },
        });
      },
      FormatTableData(data) {
        this.table_data = data.map((purchase) => {
          const date = new Date(Number(purchase.createdAt));

          return {
            _id: purchase._id,
            batchNo: purchase.serialId,
            purchaseTotal: purchase.purchaseTotal.toLocaleString(),
            purchaseBalance: purchase.purchaseBalance.toLocaleString(),
            createdBy: purchase.authorId.name,
            regDate: `${date.getDate()}/${date.getMonth() +
              1}/${date.getFullYear()}`,

            btn: "btn",
          };
        });
      },
    },
    watch: {
      getSellersPurchases: {
        deep: true,
        handler(newValue) {
          this.FormatTableData(newValue.result);
        },
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Seller", pathName: "sellers" },

        {
          displayName: "Single Seller",
          pathName: "singleSeller",
          params: { id: this.$route.params.id },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
